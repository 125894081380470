<div
  (click)="$event.stopPropagation()"
  @fadeRight
  #menu
  id="menu-container"
  *ngIf="contentMenu && userRole"
  mwlResizable
  [resizeEdges]="{right: true}"
  [enableGhostResize]="true"
  (resizeEnd)="onResizeEnd($event)"
  [ngStyle]="{'width': menuSize}"
>
    <div id="menu-content" class="dont-hide">

      <div class="header-content-menu">
              <h5 class="title-content">{{ 'cms.content.menu.contentManagement' | translate }}</h5>
              <div class="btn-close-menu">
                  <mmp5-mm-icons [icon]="['icon','icon-fechar']" (click)="closed.emit(false)"></mmp5-mm-icons>
              </div>
      </div>

      <div id="search-container" class="dont-hide search-fields-menu d-flex">
          <mmp5-fieldset-haruki
              (click)="$event.stopPropagation()"
              class="flex-grow-1"
              [class]="'hide-search-input-x'"
              [type]="'search'"
              [formControl]="search"
              [key]="'false'"
              [label]="'cms.content.menu.searchPage' | translate">
          </mmp5-fieldset-haruki>
          <div [ngClass]="'btn-filter ' + activatedFilter" (click)="filtersActivated($event)">
              <mmp5-mm-icons [icon]="['icon','icon-filtro']"></mmp5-mm-icons>
          </div>
      </div>

      <div @fadeTop class="filters-content dont-hide" (click)="$event.stopPropagation()" *ngIf="filters">
          <div id="filters-content-container">
              <mmp5-fieldset-haruki
                  [formControl]="published"
                  [checked]="published.value"
                  [type]="'checkbox'"
                  [key]="'check_published'"
                  [label]="'cms.content.menu.viewPublishedPages' | translate">
              </mmp5-fieldset-haruki>
              <mmp5-fieldset-haruki
                  [formControl]="expired"
                  [checked]="expired.value"
                  [type]="'checkbox'"
                  [key]="'check_expired'"
                  [label]="'cms.content.menu.viewExpiredPages' | translate">
              </mmp5-fieldset-haruki>
              <mmp5-fieldset-haruki
                  [formControl]="validDate"
                  [checked]="validDate.value"
                  [type]="'checkbox'"
                  [key]="'check_validDate'"
                  [label]="'cms.content.menu.viewPagesScheduled' | translate">
              </mmp5-fieldset-haruki>
              <mmp5-fieldset-haruki
                  [formControl]="noPublishedDate"
                  [checked]="noPublishedDate.value"
                  [type]="'checkbox'"
                  [key]="'check_noPublishedDate'"
                  [label]="'cms.content.menu.viewPagesWithoutPublication' | translate">
              </mmp5-fieldset-haruki>
              <mmp5-fieldset-haruki
                  [formControl]="filterById"
                  [checked]="filterById.value"
                  [type]="'checkbox'"
                  [key]="'check_filterById'"
                  [label]="'cms.content.menu.searchById' | translate">
              </mmp5-fieldset-haruki>
          </div>
      </div>

        <ng-scrollbar
            orientation="all"
            [ngClass]="activatedFilter"

            [visibility]="'hover'"
            [orientation]="'auto'"
            #scroll
            [viewClass]="'dont-hide ' + activatedFilter"
            [appearance]="compact">
            <div id="menu-content-container" class="dont-hide">
                <mat-tree
                    [dataSource]="dataSource"
                    [treeControl]="treeControl"
                    *ngIf="homePages?.length > 0; else noHomepages">
                        <!-- This is the tree node template for leaf nodes -->
                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [ngClass]="[node?.importation ? 'page-node-imported' : '']">
                            <!-- use a disabled button to provide padding for tree leaf -->

                            <div class="firstHasChild" *ngIf="!!node.homePageParent == false">

                              <div
                                  [ngClass]="{'mat-tree-node':true,'dont-hide':true, 'isHome': !!node.homePageParent == false, 'd-flex':true}"
                                  (mouseenter)="node.show = !node.show"
                                  (mouseleave)="node.show = !node.show">

                                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.title" (click)="getSubpages(node)">
                                      <mat-icon class="mat-icon-rtl-mirror">
                                        <!-- {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}} -->
                                        <mmp5-mm-icons [icon]="'icon, icon-seta-1'"></mmp5-mm-icons>
                                      </mat-icon>
                                    </button>
                                    <div [ngClass]="{'d-flex':true, 'text-menu':true, 'mt-27': node?.importation}">
                                      <a
                                          [ngClass]="[node.id === selectedPage ? 'active' : '']"
                                          [routerLink]="['/hp/',node?.homePageId]"
                                          (click)="closed.emit(false)"
                                      >

                                          <mmp5-mm-icons id="icon-home" [icon]="['icon','icon-homepages']"></mmp5-mm-icons><span>{{node.title}}</span>
                                      </a>
                                      <div class="d-flex imported-page" *ngIf="node?.importation">
                                        <p> {{ 'cms.content.menu.pageImported' | translate }}</p>
                                      </div>
                                    </div>
                                    <div
                                        [hidden]="!(node.show) && (actionsOpenId != node.id)"
                                        class="page-actions"
                                        (click)="openActionsMenu(node); $event.stopPropagation()">

                                        <page-actions [page]="node"></page-actions>
                                    </div>
                                </div>
                                <div class="noContent" *ngIf="node.expandable">
                                    <button mat-icon-button disabled></button>
                                    <a class="no-content-link" (click)="closed.emit(false)" [routerLink]="['/cms/content/page/', node.id]">
                                        {{ 'cms.content.menu.noPagesWere' | translate }} <span>{{node.title}}</span> {{ 'cms.content.menu.yet' | translate}}
                                        <span class="click"> {{ 'button.clickHere' | translate }} </span> {{ 'cms.content.menu.toStart' | translate }}!
                                    </a>
                                </div>
                            </div>
                            <div
                                class="d-flex noChild w-100"
                                *ngIf="!!node.homePageParent == true"
                                (mouseenter)="node.show = !node.show"
                                (mouseleave)="node.show = !node.show">

                                <button mat-icon-button disabled></button>
                                <a
                                    [routerLink]="['/hp/page',node.id]"
                                    (click)="closed.emit(false)"
                                    [ngClass]="[node.id === selectedPage ? 'active' : '']"
                                    [queryParams]="{parentId: node.parentId}">

                                    <span>{{node.title}}</span>
                                </a>
                                <div class="d-flex imported-page" *ngIf="node?.importation">
                                  <p>{{ 'cms.content.menu.pageImported' | translate }}</p>
                                </div>
                                <div
                                    [hidden]="!(node.show) && (actionsOpenId != node.id)"
                                    class="page-actions"
                                    (click)="openActionsMenu(node);$event.stopPropagation()">

                                    <page-actions [page]="node"></page-actions>
                                </div>
                            </div>
                        </mat-tree-node>
                        <!-- This is the tree node template for expandable nodes -->
                        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding [ngClass]="[node?.importation ? 'page-node-imported' : '']">

                            <div [ngClass]="{'mat-tree-node':true,'dont-hide':true, 'isHome': !!node.homePageParent == false, 'hasChild':true}">
                                <div class="mat-tree-node-child" (mouseenter)="node.show = !node.show" (mouseleave)="node.show = !node.show; ">
                                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.title" (click)="getPagesSubPages(node)">
                                        <!-- <em [ngClass]="{'icon': true, 'icon-seta-1': treeControl.isExpanded(node) == false, 'icon-seta-2': treeControl.isExpanded(node) == true}"></em> -->
                                        <mmp5-mm-icons [icon]="['icon',  treeControl.isExpanded(node) == false ? 'icon-seta-1': 'icon-seta-2']"></mmp5-mm-icons>
                                    </button>
                                    <div [ngClass]="{'d-flex':true, 'text-menu':true, 'mt-27': node?.importation}">
                                      <a
                                          [ngClass]="[node.id === selectedPage ? 'active' : '']"
                                          *ngIf="!!node.homePageParent == false"
                                          [routerLink]="['/hp/',node.homePageId]"
                                          (click)="closed.emit(false)"
                                          >
                                          <mmp5-mm-icons id="icon-home" [icon]="['icon','icon-homepages']"></mmp5-mm-icons><span>{{node.title}}</span>
                                      </a>
                                      <a
                                          *ngIf="!!node.homePageParent == true"
                                          [ngClass]="[node.id === selectedPage ? 'active' : '']"
                                          [routerLink]="['/hp/page',node.id]"
                                          (click)="closed.emit(false)"
                                          >
                                          <span>{{node.title}}</span>
                                      </a>
                                      <div class="d-flex imported-page" *ngIf="node?.importation">
                                        <p>{{ 'cms.content.menu.pageImported' | translate }}</p>
                                      </div>


                                    </div>
                                    <div
                                        [hidden]="!(node.show) && (actionsOpenId != node.uniqueIdentifier)"
                                        class="page-actions"  (click)="openActionsMenu(node); $event.stopPropagation()">

                                        <page-actions [delete]="deleted$" [hasInternalPages]="true" (deleted)="getHomes()" [page]="node"></page-actions>
                                    </div>

                                </div>
                            </div>
                        </mat-tree-node>
                </mat-tree>
                <div *ngIf="isUserCI" class="add-homepage">
                    <button class="btn btn-primary" (click)="createHomepage();closed.emit(false)">
                        <!-- <i id="homeButton" class="fa fa-home"></i> -->
                        <mmp5-mm-icons id="homeButton" [icon]="['fa-solid','fa-house']"></mmp5-mm-icons>
                        <span class="ms-1">{{ 'cms.content.menu.createNewHomepage' | translate}}</span>
                    </button>
                </div>
            </div>
            <ng-template #noHomepages>
                <div class="noContent noHome">
                    <a class="no-content-link" (click)="closed.emit(false)" [routerLink]="['/cms/content/firstscreen']">
                        {{ 'cms.content.menu.noHomeWere' | translate }}<span class="click"> {{ 'button.clickHere' | translate }}</span>
                        {{ 'cms.content.menu.toStartHome' | translate }}!
                    </a>
                </div>
            </ng-template>
        </ng-scrollbar>
    </div>
</div>
