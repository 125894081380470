<div class="notify-tray-list" *ngIf="items?.length">
    <ng-scrollbar externalViewport=".notify-items" [appearance]="'compact'"  [visibility]="'hover'">
        <ul class="notify-items">
            <li [ngClass]="['notify-item', !item?.viewed ? 'readed' : '', 'pointer']" *ngFor="let item of items" (click)="notifyAction(item)">
                <div class="card-item">
                    <div
                      class="notify-photo"
                      *ngIf="item?.icoUrl"
                      [style.background]="item?.icoUrl | authImage:'background':iTk:mdSrvc | async">
                    </div>
                    <div class="notify-photo no-photo" *ngIf="!item?.icoUrl">
                        <mmp5-mm-icons class="m-1" [icon]="['icon','icon-notificacoes']"></mmp5-mm-icons>
                    </div>
                    <div class="notify-content">
                        <div class="title-desc">
                            <p class="mb-0 breal-all"><strong>{{item?.title}}</strong></p>
                            <p class="break-all">{{item?.message}}</p>
                        </div>
                        <div class="warning-icon text-warning" *ngIf="item?.notifyInteraction?.requiredInteractionChk">
                            <mmp-tooltip [text]="'Esta notificação foi definida como obrigatória. Você já interagiu com ela.'" [icon]="['icon','icon-alerta']"></mmp-tooltip>
                        </div>
                    </div>
                </div>
                <div class="notify-hour">
                    <p class="mb-0">
                        <em>
                            {{formatDate(item?.sendDate)}}
                        </em>
                    </p>
                </div>
            </li>
        </ul>
    </ng-scrollbar>
</div>
<div *ngIf="!items?.length" class="empty-notify-list">
    <p>
        <ng-content></ng-content>
    </p>
</div>
