import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, Injectable, Optional, Inject } from '@angular/core';

import { InterceptorModule, NoCacheHeadersInterceptor } from './modules/interceptor.module';
import { CoreModule } from './modules/core.module';

import { AppComponent } from './app.component';
import { AlertController, NavbarComponentModule, AlertModule, BreadcrumbModule, IconBoxModule, MMIconsModule, AccessDeniedModule, PopUpComponent, PopUpModule, LoadingSpinnerModule, SafePipeModule } from 'mm-ui';
import { routing } from './app.routes';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { RecaptchaModule, RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RECAPTCHA_BASE_URL, RecaptchaLoaderService, ReCaptchaV3Service } from 'ng-recaptcha-2';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { LoginGuard } from './guards/login-guard';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { cmsSync, reducerUser } from './components/users/reducers/users.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppService } from './services/app.service';
import { reducerPendent } from './reducers/pendentItems.reducer';
import { reducerHomepageDefinition } from './components/cms/reducers/homepageDefinition.reducer';
import { EffectsModule } from '@ngrx/effects';
import { PendingItemEffects } from './effects/pendingItem.effect';
// import { DeviceDetectorModule } from 'ngx-device-detector';

import { ContentMenuModule } from './components/cms/content/menu/content-menu.module';
import { reducerPage } from './reducers/page.reducer';
import { reducerColors } from './reducers/colors.reducer';
import { HomepageColorsService } from './services/homepageColors.service';
import { CreatePageService } from './components/cms/content/create-page/create-page.service';
import { ContentService } from './components/cms/content/content.service';
import { MediaService } from './components/media/media.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogRef } from '@angular/material/dialog';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ContentMenuResolver } from './components/cms/content/content.resolver.service';
import { LoggedUserModule } from './models/loggedUser.module';
import { AppToastModule } from './modules/toast.module';
import { ToastModule } from 'primeng/toast';
import { NotifyTrayModule } from './shared/notify-tray/notify-tray.module';
import { NotifyServiceModule } from './modules/notify-service.module';
import { NotifyDialogModule } from './shared/notify-dialog/notify-dialog.module';
import { DynamicHTMLModule } from './components/view/dynamic-html';
import { components, directives } from "./components/view/dynamic-html/components";
import { NotificationGuard } from './guards/notification-guard';
import { NavbarHomepageListComponent } from './components/navbar/navbar-homepage-list/navbar-homepage-list.component';
import { NavbarAutosuggestListComponent } from './components/navbar/navbar-autosuggest-list/navbar-autosuggest-list.component';
import { ViewService } from './components/view/view.service';
import { WINDOW_PROVIDERS } from './services/window.service';
import { GlossaryHighlightServiceModule } from './modules/glossary-highlight-service.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalErrorHandler } from './handlers/global-error-handler';
import { ChatTrayModule } from './shared/chat-tray/chat-tray.module';
import { PromptComponent } from './components/prompt/prompt.component';
import { PwaService } from './services/pwa.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AutonomousComponentsModule } from './autonomous/autonomous-components.module';
// import { AngularFireModule } from '@angular/fire/compat';
// import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { MessagingService } from './services/messaging.service';
import { EventsService } from './services/events.service';
import { BehaviorSubject, Observable } from 'rxjs';

const initializer = (pwaService: PwaService) => () => pwaService.initPwaPrompt();

@Injectable()
export class PreloadedRecaptchaAPIService {
  public ready: Observable<ReCaptchaV2.ReCaptcha>;

  constructor(
    v3SiteKey?: string,
  ) {
    const readySubject = new BehaviorSubject<ReCaptchaV2.ReCaptcha>(null);
    this.ready = readySubject.asObservable();

    if (typeof grecaptcha === 'undefined') {
      const recaptchaScript = document.createElement('script');
      const renderMode = v3SiteKey || 'explicit';
      recaptchaScript.src = `https://www.google.com/recaptcha/enterprise.js?render=${v3SiteKey}`;
      document.head.appendChild(recaptchaScript);
    }

    const interval = setInterval(() => {
      if (typeof grecaptcha === 'undefined' || !(grecaptcha.render instanceof Function)) {
        return;
      }

      clearInterval(interval);
      readySubject.next(grecaptcha);
    }, 50);
  }
}

// const service = new PreloadedRecaptchaAPIService(environment.recaptcha.key);

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    ErrorPageComponent,
    NavbarHomepageListComponent,
    NavbarAutosuggestListComponent,
    PromptComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CoreModule,
    AlertModule,
    TranslateModule,
    NavbarComponentModule,
    InterceptorModule,
    routing,
    NgProgressModule,
    NgProgressRouterModule,
    NgProgressHttpModule,
    RecaptchaV3Module,
    // DeviceDetectorModule,
    DynamicHTMLModule.forRoot({ components }, { directives }),
    NotifyServiceModule.forRoot(),
    GlossaryHighlightServiceModule.forRoot(),
    LoggedUserModule.forRoot(),
    AppToastModule.forRoot(),
    BreadcrumbModule,
    IconBoxModule,
    MMIconsModule,
    AccessDeniedModule,
    PopUpModule,
    ContentMenuModule,
    NgScrollbarModule,
    MatTooltipModule,
    MatBottomSheetModule,
    ToastModule,
    NotifyTrayModule,
    NotifyDialogModule,
    LoadingSpinnerModule,
    ChatTrayModule,
    AutonomousComponentsModule,
    SafePipeModule,
    // AngularFireModule,
    // AngularFireModule,
    // AngularFireMessagingModule,
    // AngularFireModule.initializeApp(environment.firebase),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
    StoreModule.forRoot({
      'pendentItems': reducerPendent,
      'users': reducerUser,
      'homepageDefinition': reducerHomepageDefinition,
      'page': reducerPage,
      'colors': reducerColors
    }, {
      metaReducers: [cmsSync] as unknown as MetaReducer<any>[],
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      }
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    EffectsModule.forRoot([
      PendingItemEffects,
    ]),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    AlertController,
    LoginGuard,
    // { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.key },
    // { provide: RECAPTCHA_BASE_URL, useValue: environment.recaptcha.url },
    //{ provide: RecaptchaLoaderService, useValue: service },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    AppService,
    HomepageColorsService,
    CreatePageService,
    ContentService,
    MediaService,
    ContentMenuResolver,
    NotificationGuard,
    ViewService,
    MessagingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true
    },
    WINDOW_PROVIDERS,
    { provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true },
    EventsService
  ],
  exports: [
    RouterModule,
    BreadcrumbModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { }
}


