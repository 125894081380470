import { HttpClient, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { JwtModule } from "@auth0/angular-jwt";
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateMessageFormatCompiler } from "ngx-translate-messageformat-compiler";
import { RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS } from "ng-recaptcha-2";

import {
  AlertControllerModule,
  LoadingSpinner,
  LoadingSpinnerModule,
  ZoomImageModule,
  ZoomServiceController,
  MMIconsModule
} from "mm-ui";

import { AppConfig } from "../app.config";
import { MediaService } from "../components/media/media.service";
import { UsersService } from "../components/users/users.service";
import { LoggedUser } from "../models/loggedUser.service";


export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  imports: [
    JwtModule.forRoot({
      config: {
        tokenGetter
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AlertControllerModule,
    LoadingSpinnerModule,
    ZoomImageModule,
    MMIconsModule
  ],
  providers: [
    AppConfig,
    LoadingSpinner,
    ZoomServiceController,
    UsersService,
    MediaService,
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfig],
      multi: true
    },
  ]
})

export class CoreModule {

}
export function initConfig(config: AppConfig) { return () => config.load(); }
export function siteKey() { return { siteKey: AppConfig.data['recaptcha']['key'] }; }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
