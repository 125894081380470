<div class="chat-tray-content" >
  <div *ngIf="!hideHeader" class="chat-tray__header">
      <h5>
          {{'chat.tray.title' | translate}}
      </h5>
  </div>

  <div class="card-body">
      <div
        class="tab-content"
        infiniteScroll
        [scrollWindow]="false"
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="3"
        (scrolled)="onScrollDown()"
      >
          <ng-scrollbar *ngIf="!fulscream" [appearance]="'compact'"  [viewClass]="'chat-tray-scroll'" [visibility]="'hover'">
            <mm-chat-tray-data [data-fullscream]="fulscream" [data-rooms]="rooms" (chat-conversation-data)="getItem($event)"></mm-chat-tray-data>
          </ng-scrollbar>

          <mm-chat-tray-data *ngIf="fulscream" [data-fullscream]="fulscream" [data-rooms]="rooms" (chat-conversation-data)="getItem($event)"></mm-chat-tray-data>

      </div>
  </div>

  <div *ngIf="!rooms" class="tab-content">
    <mm-spinner class="spinner"></mm-spinner>
  </div>

  <div *ngIf="!hideFooter && rooms" class="see-all">
      <a class="to-chat" [routerLink]="['/chats/all-conversations']" [target]="'_blank'">
        {{ (rooms.length > 0) ? ('chat.tray.toChat' | translate ): 'Iniciar uma nova conversa'}}
      </a>
  </div>
</div>
