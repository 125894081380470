<div class="template" [ngClass]="{'hand': template.value.clickArea == 'FULL_IMAGE' }">
  <div (click)="gotoPage('image')" class="template__action">
      <img [src]="(template.value.banner || template.value?.bannerImage?.mediaUrl) | authImage:'image':iTk:mdSrvc | async" alt="">
      <!-- <img [ngSrc]="((template.value.banner || template.value?.bannerImage?.mediaUrl) | authImage:'image':iTk:mdSrvc | async)|| 'image'" priority fill alt=""> -->
      <!-- <div *ngIf="template.value?.banner || template.value?.bannerImage?.mediaUrl" [ngStyle]="{'background-size': 'cover', 'background-image': 'url('+template?.value?.banner || template.value?.bannerImage?.mediaUrl+')'}" ></div> -->
      <div class="action_content" *ngIf="template.value.displayTitle || template.value.displayDescription">
        <div class="flex-grow-1">
          <h5 class="header-bottom-banner" *ngIf="template.value.displayTitle">{{template.value.title}}</h5>
          <p class="desc-bottom-banner" *ngIf="template.value.displayDescription">{{template.value.description}}</p>
        </div>
        <div *ngIf="template.value.clickArea == 'BUTTON'">
          <button (click)="gotoPage('button')" class="btn btn-primary">{{template.value.labelButton}}</button>
        </div>
      </div>
  </div>
</div>
