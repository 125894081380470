import { NgModule } from "@angular/core";
import { GraphComponent } from "./graph.component";
import { GoogleChartsModule } from "angular-google-charts";


@NgModule({
    declarations:[
        GraphComponent
    ],
    imports:[
        GoogleChartsModule
    ],
    exports:[
        GraphComponent
    ]
})

export class GraphComponentModule {}
