import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ImageGalleryComponent } from './image-gallery.component';
import { GalleryModule, ZoomImageModule, MMIconsModule, SafePipeModule } from 'mm-ui';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxMasonryModule } from 'ngx-masonry';


@NgModule({
  declarations: [
    ImageGalleryComponent
  ],
  imports: [
    CommonModule,
    GalleryModule,
    NgScrollbarModule,
    ZoomImageModule,
    NgxMasonryModule,
    MMIconsModule,
    SafePipeModule,
    NgOptimizedImage
  ],
  exports: [
    ImageGalleryComponent
  ]
})
export class ImageGalleryModule { }
