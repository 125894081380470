<div class="page-actions-dialog-content">
    <section class="mm-box-container dark">
        <header class="mm-box-header">
            <div class="d-flex justify-content-between align-items-center">
                <h5 class="mb-0">
                    {{'dialogs.pageActions.'+type+'.title'+itemType | translate}}
                </h5>
                <mmp5-mm-icons class="pointer" (click)="close()" [icon]="['icon','icon-fechar']"></mmp5-mm-icons>
            </div>
        </header>
        <div *ngIf="messageStatus" [ngClass]="['message-alert', 'alert', messageStatus ? 'alert-'+messageStatus : '']">
            <span [innerHTML]="messageText | translate"></span>
            <div class="workflow-page-list" *ngIf="workflowOnGoingPages?.length">
                <span>{{'dialogs.pageActions.move.workflowPages' | translate}}</span>
                <ul>
                    <li *ngFor="let page of workflowOnGoingPages"><a [routerLink]="['/hp', 'page', page?.id, page?.homePageId]">{{page.title}}</a></li>
                </ul>
            </div>
        </div>
        <div *ngIf="showPageList()">
            <div class="mm-box-content pb-1">
                <h6>
                    {{'dialogs.pageActions.'+type+'.whereTo' | translate}}
                    <mmp-tooltip [text]="'dialogs.pageActions.'+type+'.desc' | translate"></mmp-tooltip>
                </h6>
                <app-page-search [treeControl]="treeControl" [page$]="pages$"></app-page-search>
            </div>
            <div class="pages">
                <ng-scrollbar externalViewport=".contents-select"  externalContentWrapper=".content-page-list">
                    <div class="contents-select">
                        <app-page-list  [showCheck]="true" [maxPages]="1" (selected)="selectWhereTo([$event])" [selectHomepage]="true" [picked$]="picked$"></app-page-list>
                    </div>
                </ng-scrollbar>
            </div>
        </div>
        <div *ngIf="type == 'copy' && itemType == 'HOMEPAGE'" class="copy-homepage">
            <p [innerHTML]="'dialogs.pageActions.copy.homepageDesc' | translate"></p>
            <mmp5-fieldset-haruki
                [formControl]="copyHomepage"
                [label]="'dialogs.pageActions.copy.homepageName' | translate"
                [key]="'copy-homepage-name'">
            </mmp5-fieldset-haruki>
        </div>
        <div class="mm-box-content pb-1">
            <div class="alert-darker p-1 mb-2" *ngIf="type == 'move'">
                <p class="mb-0">
                    {{'dialogs.pageActions.'+type+'.movingPage' | translate}}
                    <strong>{{fromPage?.title}}</strong> {{'dialogs.pageActions.'+type+'.to' | translate}}:
                    <strong>{{itemSelected?.title}}</strong>
                    <span *ngIf="!itemSelected">({{'dialogs.pageActions.'+type+'.selectPage' | translate}})</span>
                </p>
            </div>
            <div class="alert alert-danger p-1 text-center" [innerHTML]="'dialogs.pageActions.'+type+'.warning'+itemType | translate">
            </div>
        </div>
        <div class="mm-box-footer pt-1">
            <div class="d-flex justify-content-end">
                <button class="btn btn-primary" [disabled]="validateDisabled()" (click)="send()">{{'dialogs.pageActions.'+type+'.copyPagesSubpages'+itemType | translate}}</button>
            </div>
        </div>
    </section>
</div>
