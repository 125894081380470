<div class="filter__button" *ngIf="innerWidth<=425">
  <button class="btn btn-primary mt-1 mb-1" [matMenuTriggerFor]="tableMenu">{{'button.columns' | translate}}</button>
</div>
<mat-menu #tableMenu="matMenu">
  <ng-template ngFor [ngForOf]="columnNames" let-column>
    <div mat-menu-item (click)="$event.stopPropagation()">
      <mmp5-fieldset-haruki [formControl]="filter.get([column])" [type]="'checkbox'" [label]="column" [key]="'menu_'+column">
      </mmp5-fieldset-haruki>
    </div>
  </ng-template>
  <div mat-menu-item>
    <button class="btn btn-primary btn-block" (click)="setFilters()">{{'button.apply' | translate}}</button>
  </div>
</mat-menu>

<ng-scrollbar externalViewport=".table-size" class="mm-table-scroll-event" [ngStyle]="{'width': tableWidth,'max-height':tableSize}"  visibility="hover" appearence="compact" [ngClass]="expand ? 'overflow' : ''">
  <div class="table-size">
    <table class="table table-striped table-custom-bordered">
      <thead>
        <tr>
          <ng-template ngFor [ngForOf]="columnVisible" let-column let-i="index">
            <th [ngClass]="thClass[i] || 'text-center'" (click)="sort(column)">
              <span>
                {{column}}
              </span>
              <span class="tableHead__icons">
                <mmp5-mm-icons [icon]="['icon', 'icon-seta-2']" *ngIf="sortArray[i] == 'down'"></mmp5-mm-icons>
                <mmp5-mm-icons [icon]="['icon', 'icon-seta-3']" *ngIf="sortArray[i] == 'up'"></mmp5-mm-icons>
              </span>
              <mmp-tooltip
                *ngIf="headerTooltip[i]"
                [text]="headerTooltip[i].text"
                [icon]="headerTooltip[i].icon">
              </mmp-tooltip>
            </th>
          </ng-template>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let value of values; let count = index" [ngClass]="{'pointer': value?.linkTo || value?.eventEmitter}">
          <ng-template ngFor [ngForOf]="columnVisible" let-column let-i="index">
            <td (click)="linkTo(count, i)" [ngClass]="tdClass[i] || 'text-center'">
              <div [ngClass]="!value[column] ? 'd-flex justify-content-center pointer' : (value?.tdClass && value?.tdClass[i] ? value?.tdClass[i] : 'd-flex align-items-center')">
                <div class="d-flex icons__before" *ngIf="value.beforeIcons?.length > 0">
                  <div *ngFor="let icon of value.beforeIcons[i]">
                    <mmp5-mm-icons [ngClass]="[iconClass[icon] || '']" (click)="iconClick(count, $event)" [icon]="icon"></mmp5-mm-icons>
                  </div>
                </div>
                <div class="d-flex icons__before" *ngIf="value?.beforeTooltip?.length > 0">
                  <div *ngFor="let tooltip of value?.beforeTooltip[i]">
                    <mmp-tooltip [text]="tooltip.text" [icon]="tooltip.icon"></mmp-tooltip>
                  </div>
                </div>
                <div class="input-field-table" *ngIf="value[column] && isObject(value[column])">
                  <ng-template ngFor [ngForOf]="value[column]" let-input>
                    <mmp5-fieldset-haruki [ngClass]="[input.class || 'justify-content-center']" *ngIf="input.formControl" [mask]="input.mask" [checked]="input.formControl?.value" [formControl]="input.formControl" [type]="input.type" (expand)="update()"
                      [key]="input.key" [label]="input.label">
                    </mmp5-fieldset-haruki>
                  </ng-template>
                </div>
                <div class="flex-grow-1" *ngIf="!isObject(value[column]) && (value[column] != undefined)">
                  <p [ngClass]="['td_value', value?.noIcons && value?.noIcons[i] ? 'no-icons' : '']" [innerHTML]="value[column]">

                  </p>
                  <mmp-tooltip
                  *ngIf="value?.tooltip"
                  [text]="value.tooltip"
                  class="tooltip"
                  >
                  </mmp-tooltip>

                </div>
                <div class="d-flex icons__after" *ngIf="value.afterIcons?.length > 0">
                  <div *ngFor="let icon of value.afterIcons[i]">
                    <mmp5-mm-icons [ngClass]="[iconClass[icon] || '']" [icon]="icon" (click)="iconClick(count, $event)"></mmp5-mm-icons>
                  </div>
                </div>
                <div class="d-flex icons__after" *ngIf="value?.afterTooltip?.length > 0">
                  <div *ngFor="let tooltip of value?.afterTooltip[i]">
                    <mmp-tooltip [text]="tooltip.text" [icon]="tooltip.icon"></mmp-tooltip>
                  </div>
                </div>
              </div>
            </td>
          </ng-template>
        </tr>
      </tbody>
    </table>
  </div>
</ng-scrollbar>
