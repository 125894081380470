import { Injectable } from '@angular/core';
// import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import { UsersService } from '../components/users/users.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Device } from '../models/device.model';
import { NotifyService } from './notify.service';

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  device = new Device();
  token: string = "";

  constructor(
    // private angularFireMessaging: AngularFireMessaging,
    private _user: UsersService,
    private _deviceService: DeviceDetectorService,
    private _notifyService: NotifyService
  ) {
    // this.angularFireMessaging.messages.subscribe(
    //   (_messaging: any) => {
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //   }
    // );
  }

  // requestPermission() {
  //   if (Notification.permission === 'default') {
  //     if (this._deviceService.getDeviceInfo().deviceType === 'desktop') {
  //       this.getPermission()
  //     } else if (window.matchMedia('(display-mode: standalone)').matches) {
  //       this.getPermission()
  //     }
  //   }
  // }

  // getPermission(): void {
  //   this.angularFireMessaging.requestToken.subscribe({
  //     next: (token: any) => {
  //       this.token = token;
  //       this._user.getLoggedUserData().subscribe(user => this.addDeviceInfos(user));
  //     },
  //     error: (err: any) => {
  //       console.error('Não foi possível obter permissão para receber notificações.', err);
  //     }
  //   });
  // }

  // receiveMessage() {
  //   this.angularFireMessaging.messages.subscribe(
  //     (payload: any) => {
  //       console.log("Mensagem recebida no push notification. ", payload);
  //       this.currentMessage.next(payload);
  //     });
  // }

  addDeviceInfos(user) {
    const deviceInfo = this._deviceService.getDeviceInfo();

    this.device.clientId = user.clientId;
    this.device.userId = user.id;
    this.device.deviceType = deviceInfo.deviceType;
    this.device.deviceId = `${deviceInfo.browser}-${deviceInfo.deviceType}-${deviceInfo.os_version}`;
    this.device.deviceName = `${deviceInfo.browser}-${deviceInfo.deviceType}-${deviceInfo.os_version}`;
    this.device.deviceToken = this.token;

    this._notifyService.addSaveDevice(this.device).subscribe({
      next: _ => {
        console.log('Dispositivo adicionado com sucesso.');
      },
      error: _ => {
        console.log('Erro ao adicionar o dispositivo.');
      }
    });
  }
}
